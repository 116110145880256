import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Container, Row, Table } from 'react-bootstrap'
import CustomSelect from '../../../components/CustomSelect'
import { useSelector } from 'react-redux';
import { course, fee } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { format, formatDate } from 'date-fns';
import { TableResponsive } from '../../../components/common/TableResponsive';

function DemandBill() {
    const MONTHS = [
        { days: '31', value: 0, label: "January" },
        { days: '28', value: 1, label: "February" },
        { days: '31', value: 2, label: "March" },
        { days: '30', value: 3, label: "April" },
        { days: '31', value: 4, label: "May" },
        { days: '30', value: 5, label: "June" },
        { days: '31', value: 6, label: "July" },
        { days: '31', value: 7, label: "August" },
        { days: '30', value: 8, label: "September" },
        { days: '31', value: 9, label: "October" },
        { days: '30', value: 10, label: "November" },
        { days: '31', value: 11, label: "December" }
    ];
    const currentDate = new Date();
    const [courses, setCourses] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const [busy, setBusy] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());
    const [studentBill, setStudentBill] = useState([]);

    const client = useSelector(state => state.auth.client);
    const App_logo = useSelector(state => state.auth.LOGO);
    useEffect(() => {
        course.list().then(res => setCourses(res.data.course)).catch(e => e.response ? e.response.message : e.message)
    }, []);
    const fetchCourseStudentList = e => {
        setStudentList([]);
        const course_id = e.value;
        setSelectedCourse(e);
        course.students(course_id).then(res => setStudentList(res.data.student))
            .catch(e => swal.error(e.response ? e.response.data.message : e.message))
    }
    const generateDemandBill = e => {
        setSelectedStudent(e.value);
        setBusy(true);
        fee.StudentInfoFee(e.value).then(res => {
            const studentFeeInfo = res.data.student_fee_info;
            const billList = [];
            studentFeeInfo.map((sfi) => {
                const imposedAmount = sfi.imposed_amount;
                const lastPaymentDateObject = new Date(sfi.last_payment_date);
                const now = Date.now();
                const paid = sfi.fees_payment.reduce((acc, fp) => acc + parseFloat(fp.paid_amt), 0);
                if (sfi.name.toLowerCase().includes(MONTHS.find(m => m.value === selectedMonth)?.label?.toLowerCase())) {
                    billList.push({
                        name: sfi.name,
                        totalAmount: imposedAmount,
                        paid: paid,
                        dues: parseFloat(imposedAmount) - paid
                    })
                }else if (lastPaymentDateObject < now && paid < imposedAmount) {
                    billList.push({
                        name: `Back dues - ${sfi.name}`,
                        totalAmount: imposedAmount,
                        paid: paid,
                        dues: parseFloat(imposedAmount) - paid
                    })
                }
            })
            setStudentBill(billList);
        }).catch(e => swal.error(e.response ? e.response.data.message : e.message))
    }
    return (
        <div>
            <Card>
                <CardHeader>
                    <CardTitle> Demand Bill </CardTitle>
                    <CardSubtitle className='mt-3'> In this section, you can Generate, Send demand bill sms or Print Demand bill for specific students </CardSubtitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg={3} className="mb-2">
                            <CustomSelect
                                onChange={e => {
                                    setSelectedMonth(e.value);
                                    setSelectedStudent(null);
                                }}
                                defaultValue={MONTHS.filter(i => i.value === currentDate.getMonth())}
                                options={MONTHS} placeholder="Select Month"
                            />
                        </Col>
                        <Col lg={3} className="mb-2">
                            <CustomSelect
                                onChange={fetchCourseStudentList}
                                options={courses.map(c => ({ label: c.name, value: c.id }))}
                                placeholder="select Class"
                            />
                        </Col>
                        <Col lg={3} className="mb-2">
                            <CustomSelect options={studentList.map(s => ({
                                label: `${s.first_name} ${s.last_name} (#ST${s.id})`,
                                value: s.id
                            }))}
                                placeholder="select student"
                                isSearchable
                                onChange={e => generateDemandBill(e)}
                            />
                        </Col>
                        <Col lg={3} className="mb-2">
                            <button className='btn btn-primary w-100'>Generate</button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Container className="mt-4">
                {/* <div className='mb-4 d-flex justify-content-center'>
                    <button type='button' onClick={handlePrint} className='btn btn-soft-danger'>Print</button>
                </div> */}
                {(selectedStudent && selectedCourse) && studentList.map(s => s.id === selectedStudent && (
                    <Card id="printable-area">
                        <CardHeader className="d-flex justify-content-start p-3 align-items-center position-relative">
                            <img
                                src={App_logo}
                                alt="Placeholder"
                                className="rounded"
                                style={{
                                    aspectRatio: '1/1',
                                    objectFit: "cover",
                                    width: "112px"
                                }}
                            />
                            <div className='d-flex justify-content-center align-items-center flex-column w-100 position-absolute'>
                                <h1>{client?.business_name || 'School Name'}</h1>
                                <p className='mb-1'>{client?.business_address}</p>
                                <p>{client?.business_city} {client?.business_state} - {client?.business_pin}</p>
                            </div>
                        </CardHeader>
                        <CardBody className='px-4'>
                            <Row className="bg-primary rounded text-center py-2 mb-3">
                                <Col>
                                    <h5 className='text-white m-0'>DEMAND LETTER ({MONTHS.find(m => m.value === selectedMonth)?.label} {currentDate.getFullYear()})</h5>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs="6">
                                    <p><strong>Student name:</strong> {s.first_name}</p>
                                    <p><strong>Enrollment ID:</strong> #CCS{s.id}</p>
                                    <p><strong>Class:</strong> {selectedCourse.label} </p>
                                </Col>
                                <Col xs="6">
                                    <p><strong>Generated date:</strong> {format(currentDate, "PPP")}</p>
                                    <p><strong>Parent/Guardian name:</strong> {s.personal_details?.parents?.father_name}</p>
                                    <p><strong>Payment due:</strong> {studentBill.reduce((ac,b)=>ac+parseFloat(b.dues),0)} </p>
                                </Col>
                            </Row>
                            <Row className="bg-primary rounded text-center py-2 mb-3">
                                <Col>
                                    <h5 className='text-white m-0'>Fee details</h5>
                                </Col>
                            </Row>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Description</th>
                                        <th>Total amount</th>
                                        <th>Paid amount</th>
                                        <th>Pending dues</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {studentBill.map((bill, index) => {
                                        return (
                                            <tr>
                                                <td>{index+1}</td>
                                                <td>{bill.name}</td>
                                                <td>{bill.totalAmount}</td>
                                                <td>{bill.paid}</td>
                                                <td>{bill.dues}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            <Row className="mb-3">
                                <Col xs="6">
                                    <p><strong>Total fee:</strong> {studentBill.reduce((ac,b)=>ac+parseFloat(b.totalAmount),0)}</p>
                                    <p><strong>Total paid:</strong> {studentBill.reduce((ac,b)=>ac+parseFloat(b.paid),0)}</p>
                                </Col>
                                <Col xs="6">
                                    <p><strong>Total due:</strong> {studentBill.reduce((ac,b)=>ac+parseFloat(b.dues),0)}</p>
                                    <p><strong>Payment deadline:</strong> {format(currentDate,'PPP')}</p>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <p><strong>Administrator:</strong> +91-{client.business_phone}</p>
                                </Col>
                            </Row>
                            <Row className="text-center">
                                <Col>
                                    <p>This is a computer generated demand letter and does not require signature.</p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                ))}
            </Container>
        </div>
    )
}

export default DemandBill